import React from "react"
import { useLocation } from "@reach/router"
import { Footer } from "components/footer"

export const Layout = ({ children, lang = "" }) => {
  const route = useLocation()
  // 特定のページにだけクラスを付与する
  const specificPages = ["/", "/product/sns-account"]
  const isSpecificPage = specificPages.includes(route.pathname)
  return (
    <div className={"body__inner " + (isSpecificPage ? "top__inner" : "")}>
      {children}
      <Footer lang={lang} />
    </div>
  )
}
