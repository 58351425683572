import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export const Seo = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          lang
          description
          siteUrl
          locale
          fbappid
        }
      }
    }
  `)

  const title = props.pagetitle
    ? `${props.pagetitle} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title

  if (title.length > 30) {
    // console.error(`タイトルが長すぎる: ${title.length} 文字`);
  }

  const description = props.pagedesc || data.site.siteMetadata.description

  const siteUrl = props.pagepath
    ? `${data.site.siteMetadata.siteUrl}${props.pagepath}`
    : data.site.siteMetadata.siteUrl

  let imgUrl = props.pageimg
    ? `${data.site.siteMetadata.siteUrl}${props.pageimg}`
    : `${data.site.siteMetadata.siteUrl}/ogp.jpg`
  imgUrl = props.pageimg && props.pageimg.indexOf("http") >= 0 ? props.pageimg : imgUrl

  return (
    <Helmet
      title={title}
      link={[{ rel: "canonical", href: siteUrl }]}
      meta={[
        { name: "description", content: description },
        { itemprop: "name", content: title },
        { itemprop: "description", content: description },
        { itemprop: "image", content: imgUrl },
        { property: "og:title", content: title },
        { property: "og:type", content: "website" },
        { property: "og:url", content: siteUrl },
        { property: "og:image", content: imgUrl },
        { property: "og:description", content: description },
        { property: "og:site_name", content: data.site.siteMetadata.title },
        { property: "og:locale", content: data.site.siteMetadata.locale },
        { property: "og:fb:app_id", content: data.site.siteMetadata.fbappid },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
        { name: "twitter:image", content: imgUrl },
      ]}
    />
  )
}
