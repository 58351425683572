import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"

const news = [
  {
    type: "プレスリリース",
    released_at: "2023/9/27",
    title:
      "【診断コンテンツ】認知拡大・リード獲得・販売促進につながる診断コンテンツ制作プランを開始！",
    link: "https://prtimes.jp/main/html/rd/p/000000028.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2023/9/25",
    title:
      "【月額6万円/インスタントウィン新機能】自社サイトクーポンを賞品に配布できるようになりました！",
    link: "https://prtimes.jp/main/html/rd/p/000000027.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2023/9/21",
    title:
      "【アカウント運用代行】SNSマーケ支援「PARKLoT」がSNS運用をまるごと依頼できるプランを開始！",
    link: "https://prtimes.jp/main/html/rd/p/000000026.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2023/9/19",
    title:
      "【月額6万〜】UGCサイト掲載サービスPARKLoTが、カルーセル投稿の一括管理に対応！",
    link: "https://prtimes.jp/main/html/rd/p/000000025.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2023/2/28",
    title:
      "【伸びるSNSマーケ】OpenAIを活用したキャンペーン分析・リコメンド機能開発プロジェクトを始動",
    link: "https://prtimes.jp/main/html/rd/p/000000022.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2023/2/13",
    title:
      "SNSキャンペーンを支援・自動抽選ツールを提供するPARKLoTが、マイクロソフト社のスタートアップ支援プログラム「Microsoft for Startups」に採択されました",
    link: "https://prtimes.jp/main/html/rd/p/000000017.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2023/2/9",
    title:
      "【無料でインスタントウィン】Twitter自動抽選ツール「PARKLoT lite」の提供を開始",
    link: "https://prtimes.jp/main/html/rd/p/000000016.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2022/9/14",
    title:
      "【Twitter運用に悩む担当者様に朗報】フォロワー獲得の施策がまるわかりのお役立ちページを作成しました！",
    link: "https://prtimes.jp/main/html/rd/p/000000015.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2022/8/24",
    title:
      "【Twitterのクチコミ自動収集】SNSの専門家集団が提供する問い合わせ一括管理ツール。今ならベータ版を無料で使える！",
    link: "https://prtimes.jp/main/html/rd/p/000000014.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2022/7/6",
    title:
      "【メルマガで購入率アップ】応募フォームの入力が不要な「メールアドレス抽選」システム。キャンペーン参加者5,000人超を記録",
    link: "https://prtimes.jp/main/html/rd/p/000000013.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2022/6/22",
    title:
      "【診断コンテンツ】Twitterでユーザーの商品購入率を高められる診断キャンペーン。今なら初期費用無料で開催できる",
    link: "https://prtimes.jp/main/html/rd/p/000000012.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2022/5/30",
    title:
      "Twitter、Instagramの投稿を自動取得。自社サイトで商品・サービスの魅力をPRできる機能をリリース",
    link: "https://prtimes.jp/main/html/rd/p/000000011.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2022/2/28",
    title:
      "PARKLoTがSNSキャンペーンで使えるハッシュタグ自動取得ツールのモニター募集！",
    link: "https://prtimes.jp/main/html/rd/p/000000009.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2022/1/6",
    title:
      "スクラッチキャンペーン機能をリリース！わくわくの演出で爆発的にファンを増やします。",
    link: "https://prtimes.jp/main/html/rd/p/000000009.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2021/11/11",
    title:
      "「ECサイト応援キャンペーン」売上につながるTwitterフォロワーを獲得できる！インスタントウィンキャンペーンが完全無料！",
    link: "https://prtimes.jp/main/html/rd/p/000000008.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2021/10/13",
    title:
      "【新機能が無料】Twitterキャンペーンツール「PARKLoT」がAmazonギフトコードの配布を自動化！ リリース記念キャンペーンを開催",
    link: "https://prtimes.jp/main/html/rd/p/000000007.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2021/9/29",
    title:
      "【業界最安値に挑戦】SNSキャンペーンツール「PARKLoT」が初期費用無料の1周年記念キャンペーンを開催！ 10月中の期間限定",
    link: "https://prtimes.jp/main/html/rd/p/000000006.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2021/8/4",
    title:
      "Twitterインスタントウィンキャンペーンツール「PARKLoT」クーポン機能をリリース。月額でクーポン配布回数が無制限！",
    link: "https://prtimes.jp/main/html/rd/p/000000005.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2021/1/20",
    title:
      "PARKLoTが投稿をリツイートしたユーザーに、好きな内容を自動でDM送信できる機能を無料で提供開始！資料やコンテンツ配布も楽ちんに！",
    link: "https://prtimes.jp/main/html/rd/p/000000004.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2020/12/28",
    title:
      "【Twitterインスタントウィン】PARKLoTが「拡散」と「購買」を同時に喚起する、「全員当選機能」を実装！運用支援キャンペーンも同時にスタート！",
    link: "https://prtimes.jp/main/html/rd/p/000000003.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2020/11/16",
    title:
      "【SNSフォロワー数はデザイン？】約36%のユーザーが商品検討時に、SNSフォロワー数が少ないと不安と回答。法人Twitterアカウント応援の、ギフト券支援キャンペーンをPARKLoTが開催!!!",
    link: "https://prtimes.jp/main/html/rd/p/000000002.000066114.html",
  },
  {
    type: "プレスリリース",
    released_at: "2020/10/12",
    title:
      "【企業も個人もずっと無料のプレキャンツール!!】Twitterインスタントウィンキャンペーンツール・PARKLoT（パークロット）が正式ローンチ！2020年10月中にキャンペーン作成で特典も。",
    link: "https://prtimes.jp/main/html/rd/p/000000001.000066114.html",
  },
]

const News = ({ location }) => {
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="ニュース"
          pagepath={location.pathname}
          pagedesc="ニュース | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container">
              <h1 className="text-white font-bold">ニュース</h1>
            </div>
          </section>

          <section className="information__philosophy bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <ul className="news__list">
                {news.map(item => {
                  return (
                    <li className="news__item">
                      <p className="news__title">{item.type} </p>
                      <time className="news__time">{item.released_at}</time>
                      <p className="news__text">
                        <a
                          className="news__link"
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                      </p>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default News
